import { BrowserRouter as Router } from "react-router-dom";
import { MISProvider } from "./providers/MISProvider";
import { SubscriptionProvider } from "./providers/SubscriptionProvider";
import { HomeProvider } from "./providers/HomeProvider";
import { NumberDetailProvider } from "./providers/NumberDetailProvider";
import { SessionProvider } from "./providers/SessionProvider";
import { QuizProvider } from "providers/QuizProvider";
import AppRoutes from "./routes/AppRoutes";
import "./components/css/main.css";
import "./App.css";

function App() {
    return (
        <Router>
            <SessionProvider>
                <MISProvider>
                    <HomeProvider>
                        <NumberDetailProvider>
                            <SubscriptionProvider>
                                <QuizProvider>
                                    <AppRoutes />
                                </QuizProvider>
                            </SubscriptionProvider>
                        </NumberDetailProvider>
                    </HomeProvider>
                </MISProvider>
            </SessionProvider>
        </Router>
    );
}

export default App;
